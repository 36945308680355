exports.components = {
  "component---src-components-templates-blog-post-template-blog-post-template-tsx": () => import("./../../../src/components/templates/BlogPostTemplate/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-components-templates-blog-post-template-blog-post-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-company-thank-you-tsx": () => import("./../../../src/pages/company/thank-you.tsx" /* webpackChunkName: "component---src-pages-company-thank-you-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-portaldotitular-tsx": () => import("./../../../src/pages/portaldotitular.tsx" /* webpackChunkName: "component---src-pages-portaldotitular-tsx" */),
  "component---src-pages-solutions-ambulatorial-tsx": () => import("./../../../src/pages/solutions/ambulatorial.tsx" /* webpackChunkName: "component---src-pages-solutions-ambulatorial-tsx" */),
  "component---src-pages-solutions-internacao-tsx": () => import("./../../../src/pages/solutions/internacao.tsx" /* webpackChunkName: "component---src-pages-solutions-internacao-tsx" */),
  "component---src-pages-solutions-vozdopaciente-tsx": () => import("./../../../src/pages/solutions/vozdopaciente.tsx" /* webpackChunkName: "component---src-pages-solutions-vozdopaciente-tsx" */)
}

