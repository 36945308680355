module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-6fedec8672/0/cache/gatsby-plugin-google-tagmanager-npm-4.25.0-f7c88884e6-e2804b450c.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5RLB4D9","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-breakpoints-virtual-86eb467805/0/cache/gatsby-plugin-breakpoints-npm-1.3.9-07b7908b06-4a79e407f0.zip/node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(min-width: 0px)","sm":"(min-width: 576px)","md":"(min-width: 768px)","lg":"(min-width: 992px)","xl":"(min-width: 1200px)","xxl":"(min-width: 1400px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-c08c5a8182/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
